<template>
  <div class="processRout">
    <div>
      <i style="cursor:pointer" @click="goBack" class="iconfont icon-return"></i>
    </div>
    <div class="CartoonName">
      <div class="CartoonName-img">
        <img :src="dataText.icon" alt="" />
      </div>
      <div class="deaftText">
        <p>ネーム</p>
        <div class="deftext-title">
          <span> {{ dataText.name }}</span>
          <span>ネーム締切日:{{ dataText.check_at | fn }}</span>
          <span style="color:red" v-if="send == 0">未提出</span>
          <span style="color:red" v-if="send == 1">提出済</span>
          <span style="color:red" v-if="send == 3">否決</span>
        </div>
        <div class="btn-box" v-if="isEdit_">
          <span @click="yesPass">承認する</span>
          <span @click="noPass">否決する</span>
        </div>
        <div class="btn-box" v-else>
          <span @click="addNewPage">新しい頁追加</span>
          <span @click="senEmail">承認依頼</span>
        </div>
      </div>
    </div>
    <div class="constText">
      <ul>
        <li v-for="(item, index) in dataList" :key="index">
          <template>
            <div @click.stop="saveParams(item)">
              <div class="img-box"  @click="check_image(item)">
                <img v-if="item.isImage" :src="item.icon" />
                <span v-else>アップロードして下さい</span>
              </div>
              
            </div>
          </template>
          <div class="title">
            <span>第{{ item.num }}頁</span>
          </div>
          <div class="upload" v-if="item.nickname">
            <span>担当者：{{ item.nickname }}</span>
            <div class="operaio"  @click.stop="saveParams(item)">
              <span @click="fileDownLoad(item)">ダウンロード</span>
              <el-upload
                class="avatar-uploader"
                action="no"
                accept=".jpg, .jpeg, .png"
                :http-request="upLoadIcon"
                :show-file-list="false"
              >
                <span>アップロード</span>
              </el-upload>
              <!-- <span @click="check_image(item)">サムネイル</span> -->
              <span @click="delete_image(item)">削除</span>
            </div>
          </div>
          <div v-else class="tipText"  @click.stop="saveParams(item)">
            <!-- <span>ネームをアップロードして下さい</span> -->
                <el-upload
                class="avatar-uploader"
                action="no"
                accept=".jpg, .jpeg, .png"
                :http-request="upLoadIcon"
                :show-file-list="false"
              >
                <span class="uploadBtn">アップロード</span>
              </el-upload>
            <span @click="delete_image(item)">削除</span>
          </div>
          <div v-if="item.pagenote.length">
            <el-tooltip class="item" effect="dark" content="コメントあり" placement="left">
              <i style="font-size:22px;color:red" class="el-icon-bell"></i>
            </el-tooltip>
          </div>
        </li>
      </ul>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="45%">
      <div slot="title">第{{ page }} 頁</div>
      <div class="imgDetails">
        <span @click="prevPage" class="c_imgDetails_left"><i class="el-icon-arrow-left"></i></span>
        <div class="left">
          <img :src="imageUrl" alt="" fit="contain" />
        </div>
        <div class="right">
          <div class="notes">
            <h4>コメント履歴</h4>
            <ul style="max-height:400px;overflow-y:auto;margin-right:15px;padding-left:10px">
              <li v-for="(item, index) in noteArr" :key="index">
                <div>
                  {{ item.content }}
                </div>
                <p style="font-size:12px;text-align:right;color:ededed">{{item.create_at}}</p>
              </li>
            </ul>
          </div>
          <div class="noteIpt" v-if="true">
            <el-input
              type="textarea"
              placeholder="コメントを入力して下さい"
              v-model="note"
              maxlength="200"
              show-word-limit
              :autosize="{ minRows: 3, maxRows: 4 }"
            >
            </el-input>
            <!-- <span class="update" @click="updateNote()">更新</span> -->
          </div>
        </div>
        <span @click="nextPage" class="c_imgDetails_right"><i class="el-icon-arrow-right"></i></span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取　消</el-button>
        <el-button type="primary" @click="updateNote()"
          >確　定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="noPassVisible" width="45%">
       <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :close-on-click-modal="false"
      >
        <el-form-item label="送信内容" prop="desc">
          <el-input type="textarea" v-model="ruleForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="noPassVisible = false">取　消</el-button>
        <el-button type="primary" @click="noPass_">確　定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/service";
import utils from "@/assets/js/utils";
export default {
  data() {
    return {
      dataText: {},
      btn: true,
      status: false,
      imageUrl: "",
      dataList: [],
      projectId: "",
      chapterId: "",
      pageId: "",
      dialogVisible: false,
      noPassVisible: false,
      page: "",
      note: "",
      loginAttr: utils.getStorage("userInfo").data.loginattr,
      flag: false,
      role: "",
      isSend: true,
      isEdit: true,
      send: 0 ,
      userInfo: {},
      roles: [],
      noteArr: [],
      ruleForm: {
        desc: ''
      },
       rules: {
        desc: [{ required: true, message: "送信内容を入力して下さい", trigger: "blur" }],
      },
    };
  },
  computed: {
    isEdit_() {
      if(this.roles.indexOf('2') > -1) {
        return true 
      } else {
        return false
      }
    }
  },
  mounted() {
    this.getLoginInfo()
    let ps = JSON.parse(this.$route.query.params);
    this.dataText = ps;
    this.projectId = ps.projectid;
    this.chapterId = ps.id;
    let params = {
      projectid: this.projectId,
      chapterid: this.chapterId,
    };
    this.getChapterDraft(params);
  },
  methods: {
    getLoginInfo() {
      service.personal.getLoginInfo().then((res) => {
        let data = res.data;
        this.userInfo = data.data;
        this.roles = this.userInfo.role.split(",")
      });
    },
    // 上一页
    prevPage() {
      let imgIndex = this.dataList.findIndex(item => item.id === this.pageId)
      let accum = imgIndex-1
      const data = this.dataList[accum]
      if(data) {
        this.check_image(data)
      }
    },
    // 下一页
    nextPage() {
      let imgIndex = this.dataList.findIndex(item => item.id === this.pageId)
      let accum = imgIndex+1
      const data = this.dataList[accum]
      if(data && data.nickname) {
        this.check_image(data)
      } else {
        this.$message.warning("データなし");
      }
    },
    yesPass() {
      if (this.isSend) {
        let params = {
          chapterid: this.chapterId,
          projectid: this.projectId,
          ischeck: '1'
        };
        service.personal.editCheckdraft(params).then((res) => {
            if (res.status === 200) {
              this.$message.success("ネームが承認されました");
              this.$router.go(-1)
            } else if (res.status === 2) {
              this.$message.error("ネームが承認されましたので,再度の提出は不可");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("ネーム作成中，承認不可");
      }
    },
    noPass() {
      if (!this.isSend) {
        this.$message.warning("ネーム作成中，否決不可");
        return
      }
      this.noPassVisible = true;
    },
    noPass_() {
      if(this.ruleForm.desc) {
        let params = {
          projectid: this.projectId ,
          chapterid: this.chapterId,
          msg: this.ruleForm.desc
        }
        service.personal.noPass(params).then(res => {
          if(res.status === 200) {
            this.$message.success("否決されました")
            this.noPassVisible = false
          }else if(res.status === 2) {
            this.$message.error("ネームが承認されましたので,再度の提出は不可");
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.$message.warning("再申請理由を入力して下さい")
      }
    },
    updateNote() {
      let params = {
        pageid: this.pageId,
        content: this.note,
      };
      service.personal.addPageNote(params).then((res) => {
        if (res.status === 200) {
          this.$message.success("コメントを更新しました");
          this.dialogVisible = false;
              let params = {
              projectid: this.projectId,
              chapterid: this.chapterId,
            };
            this.getChapterDraft(params);
          }
      });
    },
    fileDownLoad(data) {
      let fileName = data.icon;
      let index = fileName.lastIndexOf("\/");
      fileName = fileName.substring(index + 1, fileName.length);
      window.location.href = `/chapter/downloadpage?projectid=${data.projectid}&chapterid=${data.chapterid}&pageid=${data.id}&filename=${fileName}`;
    },
    senEmail() {
      let arr = [];
      this.dataList.forEach((item) => {
        if (item.icon === null) {
          arr.push(item);
        }
      });
      if (arr.length) {
        this.$message.error("全頁のネームをアップロードした後，承認依頼が出来ます");
      } else {
        let params = {
          projectid: this.projectId,
          chapterid: this.chapterId,
        };
        this.sendEditEmail(params);
      }
    },
    //发送编辑邮件
    sendEditEmail(data) {
      service.personal
        .sendEditEmail(data)
        .then((res) => {
          if (res.status === 200) {
            this.$message.success("編集者へメールしましたので，暫くお待ちください");
            this.$router.go(-1)
          } else if (res.status === 2) {
            this.$message.warning("重複な送信は不可！")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 预览初稿
    check_image(data) {
      this.note = ""
      this.page = data.num;
      this.pageId = data.id;
      this.imageUrl = data.icon;
      this.dialogVisible = true;
      this.noteArr = data.pagenote;
    },
    //删除原稿
    delete_image(item) {
      let params = {
        projectid: this.projectId,
        chapterid: this.chapterId,
        pageid: item.id,
      };
      this.$confirm("この操作により、このファイルが削除ますので, ご確認下さい?", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return service.personal
            .deleteChapterPage(params)
            .then((res) => {
              
              if (res.status === 200) {
                this.getChapterDraft({ projectid: this.projectId,chapterid: this.chapterId});
              } else if(res.status === 2) {
                this.$message.warning("ネーム審査中の為、操作不可")
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "削除を取消しました",
          });
        });
    },
    saveParams(item) {
      this.pageId = item.id;
    },
    upLoadIcon(e) {
      if (e.file) {
        const file = e.file;
        if (this.flag) {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("projectid", this.projectId);
          formData.append("chapterid", this.chapterId);
          formData.append("pageid", this.pageId);
          service.personal.chapterUploaddraft(formData).then((res) => {
              if(res.status === 200) {
                this.iconName = res.data.filename;
                this.$message.success("ネームがアップロードされました");
                let params = {
                  projectid: this.projectId,
                  chapterid: this.chapterId,
                };
                this.getChapterDraft(params);
              } else if (res.status === 2) {
                this.$message.error(res.info)
              }

            })
            .catch(() => {
              this.$message.error("ネームのアップロードに失敗しました");
            });
        } else {
          this.$message.error("権限なし");
        }
      } else {
        this.$message.error("ファイル形式に誤っています");
      }
    },

    addNewPage() {
      let params = {
        projectid: this.projectId,
        chapterid: this.chapterId,
      };
      service.personal.creatPage(params).then((res) => {
        if (res.status === 200) {
          this.$message.success("追加しました");
          let params = {
            projectid: this.projectId,
            chapterid: this.chapterId,
          };
          this.getChapterDraft(params);
        } else if(res.status === 2) {
          this.$message.warning("審査中の為、頁追加は不可")
        }
      });
    },
    getChapterDraft(data) {
      service.personal.getChapterDraft(data).then((res) => {
          if (res.status === 200 && res.data.length) {
            this.flag = res.flag;
            this.dataList = res.data;
            this.dataList = this.dataList.map(item => {
              return {
                ...item,
                isImage: item.icon.indexOf('.') > -1 ? true : false
              }
            })
            this.role = res.position;
            this.isSend = res.issend;
            this.isEdit = res.edit;
            this.send = res.send
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  filters: {
    fn(e) {
      if (e) {
        return e.substring(0, 10);
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.processRout {
  .imgDetails {
    display: flex;
    min-height: 260px;
    position: relative;
    .c_imgDetails_left {
      position: absolute;
      top: 50%;
      font-size: 25px;
      cursor: pointer;
    }
    .c_imgDetails_right {
      position: absolute;
      right: 0;
      top: 50%;
      font-size: 25px;
      cursor: pointer;
    }
    .left {
      margin-left: 26px;
      width: 55%;
      .btn-box {
        float: right;
        > span {
          display: inline-block;
          line-height: 30px;
          margin-left: 15px;
          color: #666;
          cursor: pointer;
        }
      }
      img {
        width: 100%;
      }
    }
    .right {
      margin-left: 23px;
      width: 36%;
      p {
        line-height: 25px;
      }
      p:first-child {
        span {
          color: #333;
        }
      }
      p:last-child {
        span {
          color: #666;
        }
      }
      .notes {
        margin-top: 20px;
        h4 {
          margin-bottom: 10px;
        }
        ul {
          li {
            color: #333;
            line-height: 25px;
            border-bottom: 1px solid #ccc;
          }
        }
      }
      .noteIpt {
        margin-top: 80px;
        position: relative;
        .update {
          display: block;
          width: 60px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #f03747;
          border-radius: 8px;
          text-align: center;
          line-height: 30px;
          color: #f03747;
          margin-top: 10px;
          cursor: pointer;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
.CartoonName {
  //   border: 1px solid red;
  display: flex;
  .CartoonName-img {
    width: 248px;
    height: 150px;
    position: relative;
    border-radius: 5px;
  }
  .CartoonName-img img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 248px;
    max-height: 150px;
    border-radius: 5px;
  }
  .deaftText {
    margin-left: 20px;
    flex: 1;
    p {
      margin-top: 10px;
      font-size: 30px;
      color: #333333;
    }
    .deftext-title {
      display: flex;
      flex-wrap: nowrap;
      height: 24px;
      padding: 18px 0;
      span {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #999999;
        line-height: 24px;
        overflow: hidden; // 溢出部分隐藏
        white-space: nowrap; // 文字不换行
        text-overflow: ellipsis;
      }
      span:nth-child(1) {
        display: inline-block;
        // width: 126px;
      }
      span:nth-child(2) {
        display: inline-block;
        width: 217px;
        margin-left: 24px;
      }
    }
    .btn-box {
      // display: flex;
      span:nth-child(1) {
        display: inline-block;
        width: 120px;
        height: 39px;
        line-height: 39px;
        text-align: center;
        color: #fff;
        background: #f03747;
        border-radius: 8px;
        cursor: pointer;
      }
      span:nth-child(2) {
        display: inline-block;
        width: 120px;
        height: 39px;
        margin-left: 24px;
        line-height: 39px;
        text-align: center;
        color: #f03747;
        background: #ffffff;
        border: 1px solid #f03747;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
}
.constText {
  width: 100%;
  ul {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    width: 100%;
    li {
      border-radius: 5px;
      border: 1px solid #ffffff;
      box-shadow: #ededed 6px 6px 4px;
      //   height: 231px;
      margin: 0 10px;
      margin-top: 20px;
      width: 30%;

      .img-box {
        position: relative;
        width: 100%;
        height: 200px;
        max-height: 231px;
        text-align: center;
        span {
          color: #ccc;
          line-height: 250px;
        }
        img {
          border-radius: 5px;
          display: block;
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          cursor: pointer;
        }
      }
      .title {
        display: flex;
        padding: 0 8px;
        border-bottom: 1px solid #eeeeee;
        span {
          line-height: 39px;
          height: 39px;
        }
        > span:first-child {
          flex: 1;
          color: #333333;
        }
        > span:last-child {
          color: #f03747;
        }
      }
      .upload {
        display: flex;
        align-items: center;
        height: 45px;
        > span {
          flex: 1;
          font-size: 12px;
        }
        .operaio {
          display: flex;
          span {
            display: inline-block;
            // width: 42px;
            min-width: 42px;
            height: 22px;
            line-height: 22px;
            font-size: 12px;
          }
          // span:nth-child(2) {
          //   text-align: center;
          //   color: #fff;
          //   background: #f03747;
          //   border-radius: 6px;
          //   cursor: pointer;
          //   margin-right: 3px;
          // }
          span {
            text-align: center;
            color: #f03747;
            background: #ffffff;
            border: 1px solid #f03747;
            border-radius: 6px;
            cursor: pointer;
            margin-right: 3px;
          }
        }
      }
      .tipText {
        display: flex;
        align-items: center;
        height: 45px;
        justify-content: space-between;
        span:last-child {
            display: inline-block;
            width: 42px;
            height: 22px;
            line-height: 22px;
            font-size: 12px;
            text-align: center;
            color: #f03747;
            background: #ffffff;
            border: 1px solid #f03747;
            border-radius: 6px;
            cursor: pointer;
            margin-right: 3px;
        }
        .uploadBtn {
          display: inline-block;
            width: 120px;
            height: 22px;
            line-height: 22px;
            font-size: 12px;
            text-align: center;
            color: #f03747;
            background: #ffffff;
            border: 1px solid #f03747;
            border-radius: 6px;
            cursor: pointer;
            margin-right: 3px;
        }
      }
      // ::v-deep .avatar-uploader .el-upload {
      //   border: 1px solid #999;
      //   border-radius: 4px;
      //   cursor: pointer;
      //   position: relative;
      //   overflow: hidden;
      //   width: 100%;
      //   height: 195px;
      //   background: #f4f4f4;
      // }
      // .avatar-uploader-icon {
      //   font-size: 80px;
      //   color: #8c939d;
      //   line-height: 200px;
      // }
    }
    .weight {
      border:1px solid red ;
    }
  }
}
</style>
